import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import Paragraph from '../components/Paragraph';
import Title from '../components/Title';
import Seo from '../components/seo';

const SucessPage = ({data}) => {
  return <Layout>
    <Seo title="Succes!" />

    <Title>Tak for beskeden</Title>

    <Paragraph>
        Vi har modtaget din besked, og vi vil vende tilbage til dig snarest.
    </Paragraph>

    <Paragraph className="text-gray-500 text-sm">
        Hvis vi ikke vender tilbage til dig inden for et par dage, er der sandsynligvis sket en fejl. Prøv at sende en mail på {data.site.siteMetadata.email}.
    </Paragraph>
  </Layout>;
};

export default SucessPage;

export const query = graphql`
query {
  site {
    siteMetadata {
      email
    }
  }
}
`